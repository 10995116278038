<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>角色管理</h5>
				<div class="formgroup-inline">
					<Button label="新增" icon="pi pi-plus" class="mr-6" @click="openAddRole('add', '')" />
					<div class="field">
						<label for="roleName" class="p-sr-only">人员编号</label>
						<InputText id="roleName" type="text" v-model="roleName" placeholder="人员编号" />
					</div>
					<Button label="查询" @click="getRoleList"></Button>
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="card">
				<DataTable :value="roleList" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
					:rowHover="true" v-model:filters="filters1" filterDisplay="menu" :loading="loading1"
					:filters="filters1" responsiveLayout="scroll"
					:globalFilterFields="['name', 'country.name', 'representative.name', 'balance', 'status']">
					<template #empty>
						No customers found.
					</template>
					<template #loading>
						Loading customers data. Please wait.
					</template>

					<Column field="name" header="ID" style="min-width:12rem">
						<template #body="{ data }">
							{{ data.id }}
						</template>
					</Column>

					<Column field="name" header="角色名称" style="min-width:15rem">
						<template #body="{ data }">
							{{ data.role_name }}
						</template>
					</Column>
					<Column field="name" header="状态" style="min-width:8rem">
						<template #body="{ data }">
							<InputSwitch v-model="data.status" />
						</template>
					</Column>
					<Column header="操作" style="min-width:20rem">
						<template #body="{ data }">
							<span class="p-input-icon-left">
								<Button label="权限" class="p-button-primary p-button-outlined mr-1"
									@click="editAuth(data)" />
								<Button label="编辑" class="p-button-success p-button-text mr-1"
									@click="openAddRole('edit', data)" />
								<Button label="删除" class="p-button-danger p-button-text mr-1"
									@click="delRole(data, $event)" />
							</span>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>

	<Dialog :header="lodtitle" v-model:visible="displayAddRole" :style="{ width: '30vw' }" :modal="true">
		<div class="field p-fluid">
			<label for="roleName">角色名称</label>
			<InputText id="roleName" type="text" class="p-error" v-model="lodarr.name"
				aria-describedby="roleName-help" />
			<small id="roleName-help" class="p-error" v-show="addRoleNameError">请输入正确的角色名称，由中文、英文或者数字组成</small>
		</div>
		<template #footer>
			<Button label="No" icon="pi pi-times" @click="displayAddRole = false" class="p-button-text" />
			<Button label="Yes" icon="pi pi-check" @click="doAddRole" autofocus />
		</template>
	</Dialog>

</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import CustomerService from "../service/CustomerService";
import ProductService from '../service/ProductService';
export default {
	data() {
		return {
			customer1: null,
			customer2: null,
			customer3: null,
			filters1: null,
			filters2: {},
			displayAddRole: false,
			loading1: true,
			loading2: true,
			idFrozen: false,
			products: null,
			expandedRows: [],
			statuses: [
				'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
			],
			representatives: [
				{ name: "Amy Elsner", image: 'amyelsner.png' },
				{ name: "Anna Fali", image: 'annafali.png' },
				{ name: "Asiya Javayant", image: 'asiyajavayant.png' },
				{ name: "Bernardo Dominic", image: 'bernardodominic.png' },
				{ name: "Elwin Sharvill", image: 'elwinsharvill.png' },
				{ name: "Ioni Bowcher", image: 'ionibowcher.png' },
				{ name: "Ivan Magalhaes", image: 'ivanmagalhaes.png' },
				{ name: "Onyama Limba", image: 'onyamalimba.png' },
				{ name: "Stephen Shaw", image: 'stephenshaw.png' },
				{ name: "XuXue Feng", image: 'xuxuefeng.png' }
			],
			breadcrumbHome: { icon: 'pi pi-home', label: ' James Butt', to: '#' },
			breadcrumbItems: [
				{ label: '' }
			],

			//-----------------------------------------------
			roleList: [],
			totalCount: 0, // 数据总数
			page_index: 1, // 当前页数
			page_size: 10, // 每页条数

			roleName: '',

			lodtitle: '新增角色',
			lodarr: {
				id: '',
				name: '',
			},
			//-----------------------------------------------
		}
	},
	customerService: null,
	productService: null,
	created() {
		this.customerService = new CustomerService();
		this.productService = new ProductService();
		this.initFilters1();
	},
	mounted() {
		this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
		this.customerService.getCustomersLarge().then(data => {
			this.customer1 = data;
			this.loading1 = false;
			this.customer1.forEach(customer => customer.date = new Date(customer.date));
		});
		this.customerService.getCustomersLarge().then(data => this.customer2 = data);
		this.customerService.getCustomersMedium().then(data => this.customer3 = data);
		this.loading2 = false;

		this.getRoleList();
	},
	methods: {
		initFilters1() {
			this.filters1 = {
				'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
				'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'representative': { value: null, matchMode: FilterMatchMode.IN },
				'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
				'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
				'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
			}
		},
		getRoleList() {
			this.$http('/api/admin/role/index', {
				enterprise_id: JSON.parse(window.localStorage.getItem('userinfo')).type == 'ad' ? '' : JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
				page: this.page_index,
				role_name: this.roleName
			}).then(res => {
				if (res.code == 200) {
					res.data.data.map(e => {
						e.status = e.status == 0
					})
					this.roleList = res.data.data
					this.totalCount = res.data.total
					this.page_size = res.data.per_page
				}
			})
		},




		openAddRole(type, item) {
			if (type == 'add') {
				this.lodarr = {
					name: '',
				}
				this.lodtitle = '新增角色'
			} else {
				this.lodarr = {
					id: item.id,
					name: item.role_name
				}
				this.lodtitle = '编辑角色'
			}

			this.displayAddRole = true;
		},

		doAddRole() {
			this.$http('/api/admin/role/create', {
				enterprise_id: JSON.parse(window.localStorage.getItem('userinfo')).type == 'ad' ? '' : JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
				role_name: this.lodarr.name,
				id: this.lodarr.id
			}).then(res => {
				if (res.code == 200) {
					this.getRoleList()
					this.$toast.add({ severity: 'success', summary: '操作成功' });
				} else {
					this.$toast.add({ severity: 'error', summary: res.msg });
				}

				this.displayAddRole = false
			})
		},

		// 删除
		delRole(item, event) {

			console.log(event)

			this.$confirm.require({
				message: '确定要删除这个岗位吗？',
				target: event.currentTarget,
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.$http('/api/admin/role/del', {
						id: item.id
					}).then(res => {
						if (res.code == 200) {
							this.$toast.add({ severity: 'success', summary: '操作成功' });
							this.getRoleList();
						} else {
							this.$toast.add({ severity: 'error', summary: res.msg });
						}
					})
				},
				reject: () => {
					//callback to execute when user rejects the action
				}
			});
		},














		onRowSelect(event) {
			this.$toast.add({ severity: 'info', summary: 'Product Selected', detail: 'Name: ' + event.data.name, life: 3000 });
		},
		onRowUnselect(event) {
			this.$toast.add({ severity: 'warn', summary: 'Product Unselected', detail: 'Name: ' + event.data.name, life: 3000 });
		},
		clearFilter1() {
			this.initFilters1();
		},
		expandAll() {
			this.expandedRows = this.products.filter(p => p.id);
		},
		collapseAll() {
			this.expandedRows = null;
		},
		badgeColor(value) {
			return value > 40 ? 'success' : 'warning';
		},
		removeCompany(id) {
			console.print('remove:' + id);
		},
		editCompany(id) {
			console.print('edit ' + id);
		},
		formatCurrency(value) {
			return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
		},
		formatDate(value) {
			return value.toLocaleDateString('en-US', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			});
		},
		calculateCustomerTotal(name) {
			let total = 0;
			if (this.customer3) {
				for (let customer of this.customer3) {
					if (customer.representative.name === name) {
						total++;
					}
				}
			}

			return total;
		}
	}
}
</script>